import './App.css';
import './components/Record';
import Record from './components/Record';
import Button from './components/Button';

function App() {

  // let props = { value: 'Q'};

  const handleClick = (event) => {
    console.log(event);
  };

  return (
    <div className="App">
      <h1>Demo App</h1>
      <Record value="test"/>
      <div className="component-button-panel">
        <div>
          <Button name="AC" clickHandler={handleClick} />
          <Button name="+/-" clickHandler={handleClick} />
          <Button name="%" clickHandler={handleClick} />
          <Button name="÷" clickHandler={handleClick} orange />
        </div>
      </div>
    </div>
  );
}

export default App;
