import React from "react";

import "./Record.css";

export default class Record extends React.Component {
  // static propTypes = {
  //   value: PropTypes.string,
  // };

  // render() {

  //   fetch('http://localhost:8787/')
  //   .then((response) => response.json())
  //   .then((data) => console.log(data));


  //   return (
  //     <div className="component-display">
  //       <div>{this.props.value}</div>
  //     </div>
  //   );



    constructor() {
      super();
      this.state = {};
    }
  
    async componentDidMount() {
      const response = await fetch(`http://localhost:8787/`);
      const json = await response.json();
      this.setState(json);
      console.log(this.state);
    }
  
    render() {
      return (
        <div className="component-display">
          <div>{ this.state.message }</div>
        </div>
      );
    
  }
}